import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import styled from "styled-components";
import { AiOutlineMobile } from "react-icons/ai";
import Alert from "@material-ui/lab/Alert";
import * as emailjs from 'emailjs-com'

const PageWrapper = styled.div`
  .MuiAlert-outlinedSuccess {
    border: none;
    font-size: 1rem;
  }
  .MuiAlert-action {
    margin-right: -35px;
    margin-top: -60px;
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    border: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
  selectMenu: {
    color: "#555555",
    pointerEvents: "none",
    backgroundColor: "#fff",
  },
  formPhone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: "#555555",
    margin: "0.5rem 0",

    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  customAlert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const ReservationForm = (props) => {
  const classes = useStyles();
  const [sendStatus, setSendStatus] = React.useState(false);
  const [people, setPeople] = React.useState("");
  const [error, setError] = React.useState({
    nameerror: true,
    emailerror: false,
    phoneerror: false,
    peopleerror: false,
    dateerror: false,
    timeerror: false,
  });

  const handleChange = (event) => {
    setPeople(event.target.value);
  };

  const handleError = (name, status) => {
    setError({ ...error, name: status });
  };

  const initialFormValues = {
    customername: "",
    email: "",
    phone: "",
    people: "",
    date: "",
    time: "",
  };
  const phoneRegExp = /^([0]?[1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)$/;

  const FormSchema = Yup.object().shape({
    customername: Yup.string()
      .min(2, "Invalid.")
      .required("Name")
      .max(30, "Invalid."),
    email: Yup.string().required("Email").email("Invalid email."),
    phone: Yup.string()
      .min(5, "Invalid.")
      .max(15, "Invalid.")
     // .matches(phoneRegExp, "incorrect")
      .required("Phone No."),
    people: Yup.number().required("No. of People*"),

    date: Yup.string().required("Date"),
    time: Yup.string().required("Time"),
  });

  // function sendEmail(e) {
  //   e.preventDefault();
  //   emailjs
  //     .sendForm(
  //       "service_j5bifh4",
  //       "template_9is6j1n",
  //       e.target,
  //       "user_GAMW6fznKg875HI3nOc2g"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         console.log(e);
  //         setSendStatus(true);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         setSendStatus(false);
  //       }
  //     );
  //   e.target.reset();
  // }

  function sendEmail(values){
    emailjs.sendForm(
      "service_j5bifh4",
      "template_9is6j1n",
      "#reservation-form",
      "user_GAMW6fznKg875HI3nOc2g"
    ).then(res=>{
      console.log("successfully sent",res.status,res.text);
      setSendStatus(true);
    }).catch(err=>{
      console.log("eerr",err);
      setSendStatus(false);
    })
  }
  const timeRegExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

  const startDay = new Date().toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    time: "hh:mm",
  });
  const endDay = new Date(Date.now() + 6.048e8 * 2).toLocaleDateString(
    "fr-CA",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }
  );

  return (
    <PageWrapper>
      {sendStatus ? (
        <div className={classes.customAlert}>
          <Alert
            variant="outlined"
            severity="success"
            onClose={props.handleClose}
          >
            Thank you for submitting your request for reservation. Someone from
            Vegpoint will confirm your request.
          </Alert>
        </div>
      ) : (
        <Formik 
        initialValues={initialFormValues} 
        validationSchema={FormSchema}
        onSubmit={(values)=>{
          console.log(JSON.stringify(values,null,2));
          sendEmail(JSON.stringify(values,null,2));
        }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} id="reservation-form" autoComplete="off">
              <FormControl className={classes.formControl} fullWidth>
                {formik.touched.customername && formik.errors.customername ? (
                  <TextField
                    error
                    id="customername"
                    label={formik.errors.customername}
                    name="customername"
                    variant="outlined"
                    {...formik.getFieldProps("customername")}
                    size="small"
                    required
                  />
                ) : (
                  <TextField
                    id="customername"
                    label="Name"
                    name="customername"
                    variant="outlined"
                    {...formik.getFieldProps("customername")}
                    size="small"
                    required
                  />
                )}
              </FormControl>

              <FormControl className={classes.formControl} fullWidth>
                {formik.touched.email && formik.errors.email ? (
                  <TextField
                    error
                    id="email"
                    label={formik.errors.email}
                    name="email"
                    variant="outlined"
                    {...formik.getFieldProps("email")}
                    size="small"
                    required
                  />
                ) : (
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    variant="outlined"
                    {...formik.getFieldProps("email")}
                    size="small"
                    required
                  />
                )}
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                {formik.touched.phone && formik.errors.phone ? (
                  <TextField
                    error
                    id="phone"
                    label={formik.errors.phone}
                    name="phone"
                    variant="outlined"
                    {...formik.getFieldProps("phone")}
                    size="small"
                    required
                  />
                ) : (
                  <TextField
                    id="phone"
                    label="Phone"
                    name="phone"
                    variant="outlined"
                    {...formik.getFieldProps("phone")}
                    size="small"
                    required
                  />
                )}
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                size="small"
              >
                <InputLabel id="people" size="small">
                  No. of People
                </InputLabel>
                {formik.touched.people && formik.errors.people ? (
                  <Select
                    error
                    labelId="people"
                    id="demo-simple-select-outlined"
                    value={people}
                    size="small"
                    onChange={handleChange}
                    label={formik.errors.people}
                    name="people"
                    {...formik.getFieldProps("people")}
                    required
                  >
                    <MenuItem value={1}>One</MenuItem>
                    <MenuItem value={2}>Two</MenuItem>
                    <MenuItem value={3}>Three</MenuItem>
                    <MenuItem value={4}>Four</MenuItem>
                    <MenuItem value={5}>Five</MenuItem>
                    <MenuItem value={6}>Six</MenuItem>
                    <MenuItem value={7}>Seven</MenuItem>
                    <MenuItem value={8}>Eight</MenuItem>
                    <MenuItem value={9}>Nine</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem className={classes.selectMenu}>
                      More than 10&nbsp;&nbsp;
                      <span style={{ color: "red", fontSize: "1.1rem" }}>
                        (Book by Phone&nbsp;
                        <AiOutlineMobile style={{ fontSize: "15px" }} />)
                      </span>
                    </MenuItem>
                  </Select>
                ) : (
                  <Select
                    labelId="people"
                    id="demo-simple-select-outlined"
                    value={people}
                    size="small"
                    onChange={handleChange}
                    label="No. of People"
                    name="people"
                    {...formik.getFieldProps("people")}
                    required
                  >
                    <MenuItem value={1}>One</MenuItem>
                    <MenuItem value={2}>Two</MenuItem>
                    <MenuItem value={3}>Three</MenuItem>
                    <MenuItem value={4}>Four</MenuItem>
                    <MenuItem value={5}>Five</MenuItem>
                    <MenuItem value={6}>Six</MenuItem>
                    <MenuItem value={7}>Seven</MenuItem>
                    <MenuItem value={8}>Eight</MenuItem>
                    <MenuItem value={9}>Nine</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem className={classes.selectMenu}>
                      More than 10&nbsp;&nbsp;
                      <span style={{ color: "red", fontSize: "1.1rem" }}>
                        (Book by Phone&nbsp;
                        <AiOutlineMobile style={{ fontSize: "15px" }} />)
                      </span>
                    </MenuItem>
                  </Select>
                )}
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                {formik.touched.date && formik.errors.date ? (
                  <TextField
                    error
                    InputProps={{ inputProps: { min: startDay, max: endDay } }}
                    type="date"
                    id="outlined-basic"
                    variant="outlined"
                    name="date"
                    label={formik.errors.date}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...formik.getFieldProps("date")}
                    required
                  />
                ) : (
                  <TextField
                    InputProps={{ inputProps: { min: startDay, max: endDay } }}
                    type="date"
                    id="outlined-basic"
                    variant="outlined"
                    name="date"
                    label="Date"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...formik.getFieldProps("date")}
                    required
                  />
                )}
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                {formik.touched.time && formik.errors.time ? (
                  <TextField
                    error
                    
                    id="outlined-basic"
                    variant="outlined"
                    name="time"
                    label={formik.errors.time}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    {...formik.getFieldProps("time")}
                    required
                  />
                ) : (
                  <TextField
                  select
                    
                    id="outlined-basic"
                    variant="outlined"
                    name="time"
                    label="Time"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    {...formik.getFieldProps("time")}
                    required
                  >
                  <MenuItem value={11.00}>11:00</MenuItem>
                  <MenuItem value={11.15}>11:15</MenuItem>
                  <MenuItem value={11.30}>11:30</MenuItem>
                  <MenuItem value={11.45}>11:45</MenuItem>
                  <MenuItem value={12.00}>12:00</MenuItem>
                  <MenuItem value={12.15}>12:15</MenuItem>
                  <MenuItem value={12.30}>12:30</MenuItem>
                  <MenuItem value={12.45}>12:45</MenuItem>
                  <MenuItem value={13.00}>13:00</MenuItem>
                  <MenuItem value={13.15}>13:15</MenuItem>
                  <MenuItem value={13.30}>13:30</MenuItem>
                  <MenuItem value={13.45}>13:45</MenuItem>
                  <MenuItem value={14.00}>14:00</MenuItem>
                  <MenuItem value={14.15}>14:15</MenuItem>
                  <MenuItem value={14.30}>14:30</MenuItem>
                  <MenuItem value={14.45}>14:45</MenuItem>
                  <MenuItem value={15.00}>15:00</MenuItem>
                  <MenuItem value={15.15}>15:15</MenuItem>
                  <MenuItem value={15.30}>15:30</MenuItem>
                  <MenuItem value={15.45}>15:45</MenuItem>
                  <MenuItem value={16.00}>16:00</MenuItem>
                  <MenuItem value={16.15}>16:15</MenuItem>
                  <MenuItem value={16.30}>16:30</MenuItem>
                  <MenuItem value={16.45}>16:45</MenuItem>
                  <MenuItem value={17.00}>17:00</MenuItem>
                  <MenuItem value={17.15}>17:15</MenuItem>
                  <MenuItem value={17.30}>17:30</MenuItem>
                  <MenuItem value={17.45}>17:45</MenuItem>
                  <MenuItem value={18.00}>18:00</MenuItem>
                  <MenuItem value={18.15}>18:15</MenuItem>
                  <MenuItem value={18.30}>18:30</MenuItem>
                  <MenuItem value={18.45}>18:45</MenuItem>
                  <MenuItem value={19.00}>19:00</MenuItem>
                  <MenuItem value={19.15}>19:15</MenuItem>
                  <MenuItem value={19.30}>19:30</MenuItem>
                  <MenuItem value={19.45}>19:45</MenuItem>
                  <MenuItem value={20.00}>20:00</MenuItem>

                </TextField>


                )}
              </FormControl>
              <InputLabel className={classes.formPhone}>
                <a
                  href="tel:+358 40 6117445"
                  style={{
                    color: "#9B7930",
                    textDecoration: "none",
                    margin: "0 0.3rem",
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                >
                  <AiOutlineMobile
                    style={{ verticalAlign: "middle", marginBottom: "2px" }}
                  />{" "}
                  (+358 40 6117445)
                </a>
              </InputLabel>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  size="Large"
                >
                  Book Now
                </Button>
              </FormControl>
            </form>
          )}
        </Formik>
      )}
    </PageWrapper>
  );
};

export default ReservationForm;
