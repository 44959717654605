import React, { useState } from "react";
import styled from "styled-components";
import ukFlag from "../assets/logos/uk.svg";
import finlandFlag from "../assets/logos/finland.svg";
import { useTranslation } from "react-i18next";
import Logo from "../assets/logos/logo1.png";
import NavPage from "./NavPage";
import Menubar from "../assets/logos/menubar.svg";

const ResposiveContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 960px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
  }
`;
const NavbarContainer = styled.div`
  width: 100%;
  background: transparent;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  .active {
    background: #fff;
    opacity: 1;
    height: 55px;
    transition: 0.2s all ease-in-out;
    background: #fff;
    background-color: rgba(255, 255, 255, 1);
  }
  .cusnav {
    height: 62px;
  }
`;

const NavContentSection = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  color: #000;

  .res-logo img {
    width: 115px;
    height: auto;
    cursor: pointer;
  }

  .menu-bars {
    font-size: 1.5rem;
    z-index: 100;
    cursor: pointer;
    display: flex;
  }

  .languages {
    display: flex;
    margin-right: 3em;
  }

  .ukFlag,
  .finFlag {
    height: 35px;
    width: 45px;
    padding: 0 0.5rem;
    cursor: pointer;
  }

  @media (max-width: 480px) {
    padding: 0 1rem;
  }
`;
const MenuBars = styled.i`
  background-image: url(${Menubar});
  background-size: contain;
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 999;
`;

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const { t, i18n } = useTranslation();

  const [navpage, setNavpage] = useState(false);

  const toggle = () => {
    return setNavpage(!navpage);
  };

  const changeBackground = () => {
    if (window.scrollY > 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const handleClick = (lang) => {
    console.log(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <>
      {navpage ? (
        <NavPage navpage={navpage} setNavpage={setNavpage} />
      ) : (
        <NavbarContainer>
          <div className={navbar ? "active" : "cusnav"}>
            <ResposiveContainer>
              <NavContentSection>
                <div className="res-logo">
                  <a href="#">
                    <img src={Logo} />
                  </a>
                </div>
                <div className="menu-bars">
                  <div className="languages">
                    <img
                      src={ukFlag}
                      alt="ukFlag"
                      className="ukFlag"
                      onClick={() => handleClick("en")}
                    />
                    <img
                      src={finlandFlag}
                      alt="finlandFlag"
                      className="finFlag"
                      onClick={() => handleClick("fi")}
                    />
                  </div>
                  <MenuBars onClick={toggle} />
                </div>
              </NavContentSection>
            </ResposiveContainer>
          </div>
        </NavbarContainer>
      )}
    </>
  );
};

export default Navbar;
