import c1 from "../assets/images/C1.png";
import c2 from "../assets/images/C2.png";
import c3 from "../assets/images/C3.png";

export const Soup = [
  {
    dishname: "1. Tomato Soup  [G,L]",
    dishitems:
      "Thick and creamy tomato soup.",
    price: "5,50€",
    id: "1",
    reference: "Soup",
  },
  {
    dishname: "2. Mushroom Soup    [V,G,L]",
    dishitems:
      "Spicy soup with mix vegetables in soya sauce.",
    price: "5,90€",
    reference: "Soup",
    id: "4",
  },
  {
    dishname: ["3. Hot and Sour Soup ","[V,G,L] ",<img src={c2} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
      "Spicy lemon flavoured soup with bamboo shoots, seasoned vegetables and corn.",
    price: "5,90€",
    reference: "Soup",
    id: "2",
  },
  {
    dishname: ["4. Manchow Soup ","[V,G,L] ",<img src={c3} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
      "Spicy soup with mix vegetables in soya sauce.",
    price: "6,50€",
    reference: "Soup",
    id: "3",
  },

]
export const Appetizer = [
  {
    dishname: "5. Veg Pakauda   [V,G,L]",
    dishitems:
    "onion, cauliflower and potato coated with gram flour and deep fried.",
    price:"5,50€",
    reference:"Appetizer",
    id:"1",
  },
  {
    dishname: "6. Samosa      [V,L]",
    dishitems:
    "Deep fried crispy pastry stuffed with vegetables. Potatoes and green peas seasoned with spices (2 pcs). Served with tomato chutney.",
    price:"6,90€",
    reference:"Appetizer",
    id:"3",
  },
  {
    dishname: ["7. Chilli Mushroom ", "[V,L]",<img src={c2} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Fried fresh mushroom, capsicum in soy sauce, tomato- ginger- garlic sauce.",
    price:"6,90€",
    reference:"Appetizer",
    id:"5",
  },
  {
    dishname: ["8. Tofu chilli ",	 "[V,G,L] ",<img src={c2} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Fried fresh tofu, capsicum in spicy soy sauce, tomato ginger garlic sauce. ",
    price:"6,90€",
    reference:"Appetizer",
    id:"6",
  },
  {
    dishname: "9. Alu tikki [V,G,L]",
    dishitems:
    "Mashed potato stuffed lentils deep fried.",
    price:"6,90€",
    reference:"Appetizer",
    id:"8",
  },
  {
    dishname: "10. Peanut sandeko [V,G,L]",
    dishitems:
    "Lemon flavoured spicy peanut with chopped cucumber, onion, and tomato.",
    price:"6,50€",
    reference:"Appetizer",
    id:"10",
  },
  {
    dishname: "11. Hara-bhara kabab        [G]",
    dishitems:
    "Deep fried potato, paneer and spinach in cornflour and gram flour.",
    price:"5,20€",
    reference:"Appetizer",
    id:"7",
  },
  {
    dishname: "12. Cheese balls",
    dishitems:
    "Deep fried Potato and cheese balls with wheat flour and breadcrumbs.",
    price:"6,50€",
    reference:"Appetizer",
    id:"9",
  },
  {
    dishname: "13. Balla papdi    [G]",
    dishitems:
    "Fried Lentil balls with yogurt, mint sauce and tamarind sauce.",
    price:"5,20€",
    reference:"Appetizer",
    id:"11",
  },
  {
    dishname: "14. Paneer finger",
    dishitems:
    "Deep-fried paneer sticks coated with corn flour and breadcrumbs.",
    price:"6,90€",
    reference:"Appetizer",
    id:"2",
  },

  {
    dishname: ["15. Paneer Chilli ", "[G] ",<img src={c2} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Fried fresh cottage cheese, capsicum in spicy soy sauce, tomato ginger garlic sauce.",
    price:"5,20€",
    reference:"Appetizer",
    id:"4",
  },
]
export const Main = [
  {
    dishname: "16. Jeera alu [V,G,L]",
    dishitems:
    "Stir fried potato with cumin seeds and spices.",
    price:"8,50€",
    reference:"Main",
    id:"8"
  },
  {
    dishname: ["17. Alu govi ","[V,G,L ]", <img src={c2} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Fried cauliflower, potato and tomato cooked in onion-tomato sauce, ginger, and garlic.",
    price:"8,90€",
    reference:"Main",
    id:"11"
  },
  {
    dishname: ["18. Alu chana ","[V,G,L]",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Potato and white chickpeas in onion-tomato sauce and garlic.",
    price:"8,90€",
    reference:"Main",
    id:"12"
  },
  {
    dishname: ["19. Bhindi masala ", "[V,G,L] ",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Baby okra in medium spicy tomato- onion- ginger- garlic sauce.",
    price:"9,50€",
    reference:"Main",
    id:"13"
  },
  {
    dishname: ["20. Soya chunks masala  [V,G,L] ",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Soya balls cooked in onion-tomato sauce and ground spices.",
    price:"8,90€",
    reference:"Main",
    id:"4"
  },
  {
    dishname: ["21. Tofu Masala [V,G,L]",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Fried tofu cooked with onion-tomato sauce and ginger- garlic sauce.",
    price:"8,90€",
    reference:"Main",
    id:"6"
  },
  {
    dishname: "22. Sag Tofu  [V,G,L]",
    dishitems:
    "Tofu and spinach in ginger, garlic, tomato- sauce.",
    price:"8,90€",
    reference:"Main",
    id:"7"
  },
  {
    dishname: "23. Veg kofta [V,G,L]",
    dishitems:
    " Deep fried vegetable balls cooked in spinach-curry sauce.",
    price:"8,90€",
    reference:"Main",
    id:"14"
  },
  {
    dishname: "24. Veg Korma [V,G,L]",
    dishitems:
    "Mix vegetables in tomato-cashew- coconut cream sauce.",
    price:"9,50€",
    reference:"Main",
    id:"10"
  },
  {
    dishname: ["25. Matar Paneer [G]",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Homemade cheese and peas in onion-tomato sauce, ginger, garlic and coriander.",
    price:"8,90€",
    reference:"Main",
    id:"1"
  },
  {
    dishname: "26. Palak Paneer [G]",
    dishitems:
    "Fresh spinach and cottage cheese with creamy curry sauce.",
    price:"8,90€",
    reference:"Main",
    id:"2"
  },
  {
    dishname: "27. Sahi Paneer [G]",
    dishitems:
    "Cottage cheese cooked in tomato-butter-cashew nut cream sauce.",
    price:"9,50€",
    reference:"Main",
    id:"3"
  },

  {
    dishname: ["28. Kadai paneer   [G]",<img src={c3} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Fresh cheese capsicum and onion in spicy tomato, chilli and ginger- garlic curry cream sauce.",
    price:"9,50€",
    reference:"Main",
    id:"5"
  },

 
  {
    dishname: "29. Paneer butter masala [G]",
    dishitems:
    "Cottage cheese with onion, tomato, cashew nuts, coconut, and cream sauce.",
    price:"9,50€",
    reference:"Main",
    id:"9"
  },


  {
    dishname: "30. Malai kofta [G]",
    dishitems:
    "Mashed potatoes, cottage cheese and cashew nut balls in smooth and mellow tomato- cashew sauce with butter cream and saffron.",
    price:"9,50€",
    reference:"Main",
    id:"15"
  },
  {
    dishname: "31. Dal makhani  [G,L]",
    dishitems:
    "Stewed whole black lentil in tomato- butter and cream sauce.",
    price:"8,90€",
    reference:"Main",
    id:"16"
  },
  {
    dishname: ["32. Dal Amritsari [V,G,L]",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems:
    "Stewed mixed lentils in onion, garlic, ginger, coriander, and tomatoes.",
    price:"8,90€",
    reference:"Main",
    id:"17"
  }
]

export const ChefSpecial = [
  {
    dishname: "33. Tandoori Paneer [G]",
    dishitems:
    "Yogurt marinated, tandoori-oven grilled home-made cottage cheese with paprika, onion and tomatoes.",
    price:"12,50€",
    reference:"Tandoori",
    id:"1"
  },
  {
    dishname: "34. Tandoori Tofu Tikki [V,G,L]",
    dishitems:
    "Spices marinated , tandoori-oven grilled tofu with paprika, onion and tomatoes. ",
    price:"12,50€",
    reference:"Tandoori",
    id:"2"
  },
  {
    dishname: "35. Veg Tandoor Platter [G,L]",
    dishitems:
    "Yogurt marinated, tandoori-oven grilled cauliflower, home-made cottage cheese, zucchini, paprika and onion. ",
    price:"13,50€",
    reference:"Tandoori",
    id:"3"
  },
  {
    dishname: "36. Stuffed Aubergine  [G]",
    dishitems:
    "Eggplant stuffed with onion, potato, cheese, ginger- garlic and spices. Served with stir-fried vegetables and special dip.",
    price:"14,50€",
    reference:"ChefSpecial",
    id:"1"
  },
  {
    dishname: "37. Stuffed paprika(vegan) [V,G,L]",
    dishitems:
    "Mix Paprika with onion, potato, tomato, peas, ginger- garlic and spices baked in tandoori oven,  served with stir fried vegetables and special dip.",
    price:"13,90€",
    reference:"ChefSpecial",
    id:"2"
  },
  {
    dishname: "38. Special Biryani [G]",
    dishitems:
    "Spiced basmati rice with tomato, onion, seasoned vegetables, yogurt and saffron served with raita.",
    price:"11,50€",
    reference:"ChefSpecial",
    id:"3"
  },
  {
    dishname: "39. Green salad bowl [V,G,L]",
    dishitems:
    "Moong Sprouts, baby spinach, cherry tomato, olives, Rucola, cucumber, broccoli, avocado.",
    price:"9,90€",
    reference:"ChefSpecial",
    id:"4"
  },
  {
    dishname: "40.  Buddha bowl [G]",
    dishitems:
    "Sprouts, baby pinatti, carrot, cherry tomato, cucumber,broccoli, olives, sweet corn, cheakpeas, sesame seed, peanut, avocado, cheese cube.",
    price:"10,90€",
    reference:"ChefSpecial",
    id:"5"
  },
  {
    dishname: "41. Special mix salad [G]",
    dishitems:
    "Sprouts, baby pinatti, cherry tomato, cucumber,broccoli, olives, sweet corn, cheakpeas, sesame seed, peanut, avocado, cheese cube , pineapple, cottage cheese, corn, carrot.",
    price:"11,90€",
    reference:"ChefSpecial",
    id:"6"
  }
]
export const Snacks = [
  {
    dishname: "42. Vegetable Momo  [V,L]",
    dishitems: 
    "Wheat flour dumplings filled with finely chopped mix vegetables, onion, soya chunks, garlic, ginger, and spices and steamed to perfection. Served with specially prepared sesame & tomato dip and side salad. ",
    price:"12,50€",
    reference:"Snacks",
    id:"1"
  },
  {
    dishname: "43. Jhol momo [V,L]",
    dishitems: 
    "Vegetable momos in tomato -sesame seed- Timur, ginger and garlic sauce.",
    price:"12,50€",
    reference:"Snacks",
    id:"2"
  },
  {
    dishname: ["44. Chowmein [V,L]",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
    dishitems: 
    "Noodles stir fried with seasoned mix vegetables, tomato- soy sauce. Served with special chutney.",
    price:"10,90€",
    reference:"Snacks",
    id:"3"
  },
  {
    dishname: "45. Chole Bhature [V,L]",
    dishitems: 
    "Wheat flour deep fried bread served with cheakpeas and potato curry.",
    price:"9,90€",
    reference:"Snacks",
    id:"4"
  }
]

export const Rice =[
  {
    dishname: "Plain rice",
    dishitems:
    "",
    price:"2,50€",
    reference:"Rice",
    id:"1"
  },
  {
    dishname: "Vegetable Pulao rice",
    dishitems:
    "",
    price:"3,90€",
    reference:"Rice",
    id:"2"
  },
  {
    dishname: "Jeera rice",
    dishitems:
    "",
    price:"3,50€",
    reference:"Rice",
    id:"3"
  },
  {
    dishname: "Garlic fried rice	",
    dishitems:
    "",
    price:"3,50€",
    reference:"Rice",
    id:"4"
  },
  {
    dishname: "Mushroom rice",
    dishitems:
    "",
    price:"3,90€",
    reference:"Rice",
    id:"5"
  }
]

export const Breads =[
  {
    dishname: "Plain naan",
    dishitems:
    "",
    price:"1,90€",
    reference:"Breads",
    id:"1"
  },
  {
    dishname: "Garlic naan",
    dishitems:
    "",
    price:"2,90€",
    reference:"Breads",
    id:"2"
  },
  {
    dishname: "Tandoor roti",
    dishitems:
    "",
    price:"1,90€",
    reference:"Breads",
    id:"3"
  },
  {
    dishname: "Alu paratha",
    dishitems:
    "",
    price:"3,90€",
    reference:"Breads",
    id:"4"
  },
  {
    dishname: "Chapati",
    dishitems:
    "",
    price:"1,00€",
    reference:"Breads",
    id:"5"
  }
]
export const BeveragesCold =[
  {
    dishname: "Mango lassi",
    dishitems:
    "",
    price:"3,90€",
    reference:"BeveragesCold",
    id:"1"
  },
  {
    dishname: "Strawberry lassi",
    dishitems:
    "",
    price:"3,90€",
    reference:"BeveragesCold",
    id:"2"
  },
  {
    dishname: "Banana lassi",
    dishitems:
    "",
    price:"3,90€",
    reference:"BeveragesCold",
    id:"3"
  },
  {
    dishname: "Salt lassi",
    dishitems:
    "",
    price:"3,50€",
    reference:"BeveragesCold",
    id:"4"
  },
  {
    dishname: "Cola, Fanta, Pepsi",
    dishitems:
    "",
    price:"2,50€",
    reference:"BeveragesCold",
    id:"5"
  },
  {
    dishname: "Salted Lime soda",
    dishitems:
    "",
    price:"2,50€",
    reference:"BeveragesCold",
    id:"6"
  },
  {
    dishname: "Sweet lime soda",
    dishitems:
    "",
    price:"2,50€",
    reference:"BeveragesCold",
    id:"7"
  },
  {
    dishname: "Juice (Appelsii ni, Omena, Karpalo)",
    dishitems:
    "",
    price:"3,00€ ",
    reference:"BeveragesCold",
    id:"8"
  }
]
export const BeveragesHot =[
  {
    dishname: "Coffee",
    dishitems:
    "",
    price:"2,50€",
    reference:"BeveragesHot",
    id:"1"
  },
  {
    dishname: "Tea (Black /Milk/ Green)",
    dishitems:
    "",
    price:"2,50€",
    reference:"BeveragesHot",
    id:"2"
  },
  {
    dishname: "Masala Chai Tea",
    dishitems:
    "",
    price:"3,50€",
    reference:"BeveragesHot",
    id:"3"
  },
  {
    dishname: "Milk",
    dishitems:
    "",
    price:"2,00€",
    reference:"BeveragesHot",
    id:"4"
  }
]

export const Deserts =[
  {
    dishname: "Ice cream",
    dishitems:
    "(Vanilla, chocolate, moango and strawberry)",
    price:"3,00€",
    reference:"Deserts",
    id:"1"
  },
  {
    dishname: "Mixed Ice cream",
    dishitems:
    "(Mix of selected three ice creams)",
    price:"5,00€",
    reference:"Deserts",
    id:"2"
  },
  {
    dishname: "Kulfi",
    dishitems:
    "Condensed milk-parfait containing pistacho and almonds, mildly flavoured with cardamom.",
    price:"4,50€",
    reference:"Deserts",
    id:"3"
  }
]

export const Kids = [
  {
    dishname:"French Fries",
    dishitems:"",
    price:"5,00€",
    reference:"Kids",
    id:"1"
  }
]