import React from "react";
import styled from "styled-components";
import NavImg from "../assets/images/LandingImg2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FaTimes } from "react-icons/fa";
import InstagramLogo from "../assets/logos/instagram.svg";

const ResposiveContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 960px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: 100%;
    max-width: 1640px;
    margin: 0 auto;
  }
`;

const NavPageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  z-index: 9999;
  background: #fff;
  transition: 0.5s all ease-in-out;
`;

const ContentSection = styled.div`
  padding: 1.5rem;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  position: relative;

  .cross {
    position: absolute;
    right: 4%;
    top: 5%;
    font-size: 2rem;
    cursor: pointer;

    @media (max-width: 480px) {
      right: 8%;
    }
  }
`;

const LeftSection = styled.div`
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;

  img {
    width: 70%;
    height: 100%;
    transform: scale(1.1) translate3d(-5%, 0, 0);

    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 768px) {
      width: 90%;
      height: 90%;
      transform: none;
    }
  }
  @media (max-width: 768px) {
    width: 60%;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;
const RightSection = styled.div`
  width: 30%;
  height: 100%;
  position: relative;

  @media (max-width: 768px) {
    width: 40%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const NavLinks = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(235, 229, 219, 0.4);
  padding: 1rem;
  a {
    text-decoration: none;
    font-family: "Rokkitt";
    font-size: 2rem;
    line-height: 2;
    color: #ada8a8;

    width: 100%;
    height: 70px;
    margin: 0.1rem 0;
    cursor: pointer;
    background: #fffff8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #efba31;
    }
  }
`;
const SocialIcons = styled.div`
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 3%);
  a {
    display: inline-block;
    color: #fff;
    margin: 0 0.5rem;
    font-size: 1rem;
    transition: transform 250ms;
    vertical-align: center;
    height: 100%;
    img {
      width: 26px;
      height: auto;
    }
  }
  a:hover {
    transform: translateY(-3px);
  }

  .facebook {
    color: #0e8cf1;
  }

  .instagram {
    color: #000;
  }
`;

const NavPage = ({ navpage, setNavpage }) => {
  return (
    <NavPageContainer>
      <ResposiveContainer>
        <ContentSection>
          <LeftSection>
            <img src={NavImg} />
          </LeftSection>
          <RightSection>
            <NavLinks>
              <a href="#" onClick={() => setNavpage(false)}>
                <div> Home</div>
              </a>
              <a href="#menuSection" onClick={() => setNavpage(false)}>
                <div>Menu</div>
              </a>
              <a href="#reservation" onClick={() => setNavpage(false)}>
                <div> Reservation</div>
              </a>
              <a href="#reservation" onClick={() => setNavpage(false)}>
                <div>Contact Us</div>
              </a>
            </NavLinks>
            <SocialIcons className="soc-icons">
              <a
                href="https://www.facebook.com/Vegpoint.fi"
                target="_blank"
                className="facebook social"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>

              <a
                href="https://www.instagram.com/vegpoint.fi"
                target="_blank"
                className="instagram social"
              >
                <img src={InstagramLogo} />
              </a>
            </SocialIcons>
          </RightSection>
          <div className="cross">
            <FaTimes onClick={() => setNavpage(false)} />
          </div>
        </ContentSection>
      </ResposiveContainer>
    </NavPageContainer>
  );
};

export default NavPage;
