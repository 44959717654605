import React from "react";
import styled from "styled-components";
import DividerImg from "../assets/images/divider.png";
import ScrollDown from "./ScrollDown";
import { useTranslation } from "react-i18next";
import pdf from "../assets/menu.pdf";


const ResposiveContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    max-width: 1640px;
  }
`;

const PageContainer = styled.div`
  overflow: hidden;
  height: auto;
  width: 100%;
  z-index: -1;
  padding-bottom: 8em;
`;
const MenuContainerBackground = styled.div`
  height: auto;
  background: #fafafa;
`;
const MenuContainer = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 4rem;
  position: relative;

  .divider-img img {
    width: 100px;
    height: 16px;
    display: flex;
    margin: 2rem auto;
    @media (max-width: 480px) {
      margin: 1rem auto;
    }
  }
  @media (max-width: 480px) {
    height: auto;
  }
`;
const FoodBtnSection = styled.div`
  width: 250px;
  height: 3.75rem;
  background-color: #fafafa;
  border: 1px solid #e4dfdf;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translate(-50%, -110%);
  transition: 0.5s all ease-in-out;

  &:hover {
    cursor: pointer;
    background: linear-gradient(349deg, #e5e5e5 40%, #fff 70%);
    box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.3);
    outline: none;
    transform: translate(-50%, -110%) scale(1.05);
    .menutext {
      visibility: visible;
    }
  }

  .menutext {
    visibility: hidden;
    min-width: 120px;
    width: auto;
    background: #fafafa;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 7px 0;
    font-size: 1rem;
    color: #6b6767;

    /* position of menutext */
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 70%;
  }
`;
const FoodContainer = styled.div`
  letter-spacing: 0.25rem;
  font-family: Rokkitt;
  font-style: normal;
  font-weight: normal;
  font-size: 1.875rem;
  color: #6b6767;
`;

const MenuSection = styled.div`
  letter-spacing: 0.13rem;
  font-family: Rokkitt;
  font-style: normal;
  font-size: 1.063rem;
  color: #6b6767;
`;
const TopContentSection = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  font-family: "Rhodium Libre", serif;
  font-weight: 25rem;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.57);
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
    padding-top: 2rem;
    font-size: 1rem;
  }
  @media (max-width: 480px) {
    width: 93%;
    padding-top: 1rem;
  }
`;

const FreshLocalSection = styled.div`
  letter-spacing: 0.25rem;
  font-family: "Rokkitt";
  font-style: normal;
  font-weight: normal;
  font-size: 2.18rem;
  color: #6b6767;
  text-align: center;
`;

const ExploreMenuSection = styled.div`
  letter-spacing: 0.13rem;
  font-family: "Roboto";
  font-style: normal;
  font-size: 1.5rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #575050;
  text-align: center;
  margin-top: 0.2rem;
`;

const ExploreMenu = () => {
  const { t } = useTranslation();
  return (
    <PageContainer id="menuPage">
      <MenuContainerBackground>
        <ResposiveContainer>
          <MenuContainer>
            <TopContentSection>{t("ExploreMenu.Text")}</TopContentSection>
            <div className="divider-img">
              <img src={DividerImg} />
            </div>
            <FreshLocalSection>
              {t("ExploreMenu.freshAndLocal")}
            </FreshLocalSection>
            <ExploreMenuSection>
              {t("ExploreMenu.exploreOurMenu")}
            </ExploreMenuSection>
            <ScrollDown nextLocation="#menuSection" />{" "}
            <a href ={pdf} target="_blank">
            <FoodBtnSection>
              <FoodContainer>{t("ExploreMenu.food")}</FoodContainer>
              <MenuSection>{t("ExploreMenu.menu")}</MenuSection>

              <span className="menutext">View Menu</span>
            </FoodBtnSection>
            </a>
          </MenuContainer>
        </ResposiveContainer>
      </MenuContainerBackground>
    </PageContainer>
  );
};

export default ExploreMenu;
