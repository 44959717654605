const galleryImages = [
  {
    name: "Flowerpot",
    src:
      "https://images.pexels.com/photos/4041395/pexels-photo-4041395.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    alt: "flowerpot",
    shortdescription: "dfsadfdsfsafdas",
    width: 3,
    height: 4,
  },
  {
    name: "Plate",
    src:
      "https://i.pinimg.com/originals/9a/81/97/9a81975c7262954391b4bf1c760b2e77.jpg",
    alt: "plate",
    shortdescription: "dsfasfdsafdsf",
    width: 5,
    height: 3,
  },
  {
    name: "Pasta",
    src:
      "https://www.kaffeeform.com/wp-content/uploads/2019/06/Kaffeeform-kaffeesatz-tasse-cup-cappuccino-hero-e1565003554831.jpg",
    alt: "pasta",
    shortdescription: "fsdfsdafdsaf",
    width: 2,
    height: 2,
  },
  {
    name: "Gravy",
    src:
      "https://images.pexels.com/photos/269257/pexels-photo-269257.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    alt: "gravy",
    shortdescription: "sadfsdafdsafsaf",
    width: 3,
    height: 3,
  },
  {
    name: "Spoon",
    src:
      "https://images.pexels.com/photos/1128678/pexels-photo-1128678.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    alt: "spoon",
    shortdescription: "sadfsdafdsafsaf",
    width: 2,
    height: 1,
  },

  {
    name: "Extras",
    src:
      "https://images.pexels.com/photos/4281818/pexels-photo-4281818.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    alt: "clams",
    shortdescription: "dfssdfsadff",
    width: 5,
    height: 2,
  },
];

export default galleryImages;
