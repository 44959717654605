import React from "react";
import styled from "styled-components";
import { AiOutlineDown } from "react-icons/ai";

const ScrollContainer = styled.div`
  .scroll-down a {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 18%;
    bottom: 1%;
    transform: translate(-18%, -1%);
    font-family: "Rokkitt";
    font-weight: 400;
    font-size: 15px;
    color: #a29b9b;
    letter-spacing: 0.02em;
    text-decoration: none;
  }
  .down-icon {
    font-size: 1.5rem;
  }

  @media (max-width: 1024px) {
    height: auto;
  }
  @media (max-width: 768px) {
    .scroll-down a {
      right: 5%;
    }
  }
  @media (max-width: 480px) {
    .scroll-down a {
      display: none;
    }
  }
`;
const ScrollDown = (props) => {
  return (
    <ScrollContainer>
      <div className="scroll-down">
        <a href={props.nextLocation}>
          scroll down
          <div className="down-icon">
            <AiOutlineDown />
          </div>
        </a>
      </div>
    </ScrollContainer>
  );
};

export default ScrollDown;
