import c1 from "../assets/images/C1.png";
import c2 from "../assets/images/C2.png";
import c3 from "../assets/images/C3.png";
export const Monday = [
  {
      dishname: ["1. Alu Chana  (Vegan) [G,L]", <img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
      dishitems:
        "Potato and white chickpeas in onion-tomato sauce and garlic.",
      price: "9,95€",
      id: "1",
      reference: "Monday",
    },
    {
      dishname: ["2. Soya-ball Masala (Vegan) [G,L] ",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
      dishitems:
        "Soya balls cooked in onion-tomato sauce and ground spices.",
      price: "9,95€",
      id: "2",
      reference: "Monday",
    },
    {
      dishname: "3. Sag Paneer (Creamy)	            [G]",
      dishitems:
        "Fresh spinach and cottage cheese with creamy curry sauce.",
      price: "9,95€",
      id: "3",
      reference: "Monday",
    },
    {
      dishname: "4. Vegetable Bahar (creamy)         [G,L]",
      dishitems:
        "Mix vegetables cooked in tomato-onion and cashewnut sauce. ",
      price: "9,95€",
      id: "4",
      reference: "Monday",
    },
    {
      dishname: "5. Chefs special Biryani 		 [G,L]",
      dishitems:
        "Spiced basmati rice with tomato, onion, seasoned vegetables, yogurt, spices and saffron served with raita.",
      price: "10,95€",
      id: "5",
      reference: "Monday",
    }
]

export const Tuesday = [
  {
      dishname: "1. Jeera Alu (Vegan) 		[G,L]",
      dishitems:
        "Stir fried potato with cumin seeds and spices.",
      price: "9,95€",
      id: "1",
      reference: "Tuesday",
    },
    {
      dishname: ["2. Tofu masala (Vegan) [G,L]",<img src={c1} height="30" style={{verticalAlign:'middle'}}></img>],
      dishitems:
        "Fried tofu cooked with onion-tomato sauce and ginger- garlic sauce.",
      price: "9,95€",
      id: "2",
      reference: "Tuesday",
    },
    {
      dishname: "3. Veg Korma (Creamy) 		[G,L]",
      dishitems:
        "Mix vegetables in tomato-cashew- coconut cream sauce.",
      price: "9,95€",
      id: "3",
      reference: "Tuesday",
    },
    {
      dishname: "4. Paneer butter masala (Creamy) 	[G,L]	",
      dishitems:
        "Cottage cheese with onion, tomato, cashew nuts, coconut, and cream sauce.",
      price: "9,95€",
      id: "4",
      reference: "Tuesday",
    },
    {
      dishname: "5. Chefs special Stuffed Paprika 		[G,L]",
      dishitems: 
        "Paprika stuffed with onion, potato, tomato, peas, ginger- garlic and spices baked in tandoori oven, served with stir fried vegetables and special dip.",
      price: "10,95€",
      id: "5",
      reference: "Tuesday",
    }
]

export const Wednesday = [
  {
    dishname: "1. Alu Govi (Vegan)              [G,L] ",
    dishitems:
      "Fried cauliflower, potato and tomato cooked in onion-tomato sauce, ginger, and garlic.",
    price: "9,95€",
    id: "1",
    reference: "Wednesday",
  },
  {
    dishname: "2. Sag Tofu (vegan) 		  [G,L]",
    dishitems: "Tofu and spinach in ginger, garlic, tomato- sauce.",
    price: "9,95€",
    id: "2",
    reference: "Wednesday",
  },
  {
    dishname: [
      "3. Kadai Paneer (Creamy) [G]",
      <img src={c1} height="30" style={{ verticalAlign: "middle" }}></img>,
    ],
    dishitems:
      "Fresh cheese, capsicum and onion in spicy tomato, chilli, and ginger- garlic curry cream sauce.",
    price: "9,95€",
    id: "3",
    reference: "Wednesday",
  },
  {
    dishname: "4. Matar Mushroom (cream)	[G,L]",
    dishitems: "Mushroom and peas in onion- tomato creamy sauce.",
    price: "9,95€",
    id: "4",
    reference: "Wednesday",
  },
  {
    dishname: "5. Chefs special Jhol Momo	[L]",
    dishitems:
      "Wheat flour dumplings filled with finely chopped mix vegetables, onion, soya chunks, garlic, ginger, and spices and steamed to perfection, served in tomato -sesame seed- Timur, ginger and garlic sauce. ",
    price: "10,95€",
    id: "5",
    reference: "Wednesday",
  },
];

export const Thursday = [
  {
    dishname: "1. Alu Matar  (Vegan)                [G,L]",
    dishitems:
      "Potato and peas in onion-tomato sauce, ginger, garlic, and coriander.",
    price: "9,95€",
    id: "1",
    reference: "Thursday",
  },
  {
    dishname: [
      "2. Chilly Tofu (vegan) [G,L] ",
      <img src={c1} height="30" style={{ verticalAlign: "middle" }}></img>,
    ],
    dishitems:
      "Fried fresh tofu, capsicum in spicy soy tomato ginger garlic sauce.",
    price: "9,95€",
    id: "2",
    reference: "Thursday",
  },
  {
    dishname: "3. Shahi Paneer (Creamy)		[G]",
    dishitems: "Cottage cheese cooked in tomato-butter-cashew cream sauce.",
    price: "9,95€",
    id: "3",
    reference: "Thursday",
  },
  {
    dishname: "4. Bhindi Masala (Vegan) 		[G,L]",
    dishitems:
      "Fried okra, potatoes, with tomato, ginger & garlic masala sauce.",
    price: "9,95€",
    id: "4",
    reference: "Thursday",
  },
  {
    dishname: "5. Chefs Special Dal Makhani	 [G,L]",
    dishitems: "Stewed whole black lentil in tomato- butter and cream sauce.",
    price: "10,95€",
    id: "5",
    reference: "Thursday",
  },
];

export const Friday = [
  {
    dishname: "1. Alu Paprika (Vegan) 		[G,L] ",
    dishitems: "Potato and paprika in onion-tomato and garlic sauce.",
    price: "9,95€",
    id: "1",
    reference: "Friday",
  },
  {
    dishname: "2. Sag Tofu (vegan) 		  [G,L]",
    dishitems: "Tofu and spinach in ginger, garlic, tomato- sauce.",
    price: "9,95€",
    id: "2",
    reference: "Friday",
  },
  {
    dishname: "3. Mix vegetable curry (Creamy)	 	[G,L] ",
    dishitems: "Mix vegetables in tomato-cashew- coconut cream sauce.",
    price: "9,95€",
    id: "3",
    reference: "Friday",
  },
  {
    dishname: "4. Paneer masala 			[G]  ",
    dishitems:
      "Fried cottage cheese and tomato with ginger garlic and masala sauce.",
    price: "9,95€",
    id: "4",
    reference: "Friday",
  },
  {
    dishname: "5. Chefs Special mix vegetable tofu	       [G,L]  ",
    dishitems: "Seasonal mix vegetables with tofu cooked in special sauce.",
    price: "10,95€",
    id: "5",
    reference: "Friday",
  },
];
