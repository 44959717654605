import React from "react";
import styled from "styled-components";
import DividerImg from "../assets/images/divider.png";
import Gallery from "react-photo-gallery";
import galleryImages from "../data/GalleryImg";
import { AiOutlineDown } from "react-icons/ai";

const GalleryContainer = styled.div`
  height: auto;
  width: 100%;
  background: #fafafa;
  @media (max-width: 1024px) {
    height: auto;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;
const HeadWrapper = styled.div`
  width: 100%;
  height: 17vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    text-align: center;
    font-family: "Rokkitt", serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2;
    letter-spacing: 0.25rem;
    color: #6b6767;
  }

  .divider-img img {
    width: 100px;
    height: 16px;
  }
`;
const GalleryWrapper = styled.div`
  width: 90%;
  height: auto;
  margin: 0 auto;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  /* grid-gap: 1.5rem;
  grid-template-areas:
    "img-1 img-2 img-3 img-4"
    "img-1 img-5 img-6 img-6"; */

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const ScrollWrapper = styled.div`
  height: 5vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .down-icon {
    font-size: 1.3rem;
    color: #bdbaba;
  }
`;

const MenuGallery = () => {
  return (
    <GalleryContainer id="gallery">
      <HeadWrapper>
        <h2>Gallery Section</h2>
        <div className="divider-img">
          <img src={DividerImg} />
        </div>
      </HeadWrapper>
      <GalleryWrapper>
        <Gallery photos={galleryImages} />
      </GalleryWrapper>
      <ScrollWrapper>
        <a href="#reservation">
          <div className="down-icon">
            <AiOutlineDown />
          </div>
        </a>
      </ScrollWrapper>
    </GalleryContainer>
  );
};

export default MenuGallery;
/*
 {GalleryImages.map((item, index) => {
          return (
            <MenuGallery
              id={index}
              key={index}
              name={item.name}
              image={item.image}
              alt={item.alt}
              description={item.shortdescription}
              class={item.class}
            />
          );
        })}
*/
