import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styled from "styled-components";
import VegpointLogo from "../assets/logos/logo1.png";
import { IoCloseOutline } from "react-icons/io5";
import { GiDividedSquare } from "react-icons/gi";

const ModalBanner = styled.div``;
const ContentWrapper = styled.div`
  text-align: center;
  /* max-width: 50%;
  margin: 0 auto; */

  img {
    width: 100px;
    height: auto;
  }
  .banner_message {
    padding: 0.5rem;
    color: #003e0b;
    font-size: clamp(12px, 14px, 1.5rem);
    text-align: justify;
  }

  .divider_line {
    p {
      font-size: 1.2rem;
      position: relative;
      color: #9b7930;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p::before {
      content: "";
      border-bottom: 1px solid #9b7930;
      width: 5vw;
      margin-right: 1rem;
      @media (max-width: 480px) {
        width: 10vw;
      }
    }
    p::after {
      content: "";
      border-bottom: 1px solid #9b7930;
      width: 5vw;
      margin-left: 1rem;
      @media (max-width: 480px) {
        width: 10vw;
      }
    }
  }
`;
const CloseIcon = styled.div`
  position: absolute;
  right: 5%;
  top: 8%;
  font-size: clamp(12px, 1.5rem, 2rem);
  color: #003e0b;
  cursor: pointer;

  @media (max-width: 768px) {
    right: 3%;
    top: 6%;
  }
  @media (max-width: 480px) {
    right: 6%;
    top: 5%;
  }
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "40%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
  },
  paper: {
    minHeight: "20vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: "16px",
    outline: "none",
    border: "none",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
}));

export default function OfferBanner() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalBanner>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <CloseIcon>
              <IoCloseOutline onClick={() => setOpen(false)} />
            </CloseIcon>
            <ContentWrapper>
              <img id="transition-modal-title" src={VegpointLogo}></img>
              <p id="transition-modal-description" className="banner_message">
                Dear customer, Our kitchen will remain open during lockdown
                (08/03/2021- 28/03/2021). We have{" "}
                <span
                  style={{
                    color: "#9B7930",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  15%
                </span>{" "}
                discount on all takeaways. You can place the order by phone{" "}
                <a
                  href="tel:+358 40 6117445"
                  target="_blank"
                  style={{
                    color: "#9B7930",
                    textDecoration: "none",
                  }}
                >
                  (+358 40 6117445)
                </a>
                . Stay safe and take care of each other.
              </p>
              <div className="divider_line">
                <p>
                  {" "}
                  <GiDividedSquare />
                </p>
              </div>
              <p className="banner_message">
                Hyvä asiakkaamme, Keittiömme palvelee myös ravintoloiden sulun
                aikana (08/03/2021 - 28/03/2021). Paikan päälle ei voi jäädä
                syömään, mutta myymme mukaan annoksia. Meillä on{" "}
                <span
                  style={{
                    color: "#9B7930",
                    textDecoration: "none",
                  }}
                >
                  15%
                </span>{" "}
                alennusta 08.03-28.03 välisenä aikana kaikissa annoksissa. Voit
                tehdä tilauksen puhelimitse{" "}
                <span
                  style={{
                    color: "#9B7930",
                    textDecoration: "none",
                  }}
                >
                  (+358 40 6117445)
                </span>
                . Pysy turvassa ja pidä huolta itsestäsi ja muista.
              </p>
            </ContentWrapper>
          </div>
        </Fade>
      </Modal>
    </ModalBanner>
  );
}

//  <img src={Divider} className="dividerImg" />
