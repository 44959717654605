import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import Tabs from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Items from "./Items";
import styled from "styled-components";
import DividerImg from "../../assets/images/divider.png";
import { Container } from "@material-ui/core";

import {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
} from "../../data/Lunchdata";
import { useTranslation } from "react-i18next";

const ResposiveContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 100%;
  }
  @media (min-width: 1280px) {
    max-width: 1640px;
  }
`;
const MenuContainerBackground = styled.div`
  background-color: #fafafa;
`;

const MainMenuContainer = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;
  .menuNav {
  }

  .extraInfo {
    text-align: center;
    padding: 4rem;
    font-size: 1.4rem;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }
  .category__vgl {
    p {
      font-size: 1.4rem;
      color: #6b6767;
      position: relative;
    }

    p::before {
      content: "";
      position: absolute;
      width: 20vw;
      border-bottom: 1px solid #c9c7c7;
      top: -55%;
      left: 0;
    }
    p::after {
      content: "";
      position: absolute;
      width: 20vw;
      border-bottom: 1px solid #c9c7c7;
      bottom: -55%;
      right: 0%;
      @media (max-width: 480px) {
        bottom: -25%;
        width: 35vw;
      }
    }
  }
`;

const HeadWrapper = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  h2 {
    text-align: center;
    font-family: "Rokkitt", serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2;
    letter-spacing: 0.25rem;
    color: #6b6767;
  }

  .divider-img img {
    width: 100px;
    height: 16px;
  }
`;

const NoteContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: -50px;
  margin-left: -40px;
  @media (max-width: 1280px) {
    margin-left: -10px;
    margin-top: -70px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin: 0;
  }

  @media (max-width: 480px) {
    justify-content: center;
    margin: 0;
  }
`;

const NoteInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1rem;
  width: 42%;
  color: red;
  font-size: 1.2rem;
  border-radius: 8px;
  font-style: italic;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
    text-align: center;
  }
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FAFAFA",
    fontFamily: "Rokkitt",
    padding: "6px 1px",
  },
  menuNav: {
    backgroundColor: "#FAFAFA",
  },
  tab: {
    color: "#6B6767",
    fontSize: "0.8rem",
    letterSpacing: "0.02em",
    padding: "6px 2px",
  },
});

export default function MainMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState("Monday");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainMenuContainer id="menuSection">
      <HeadWrapper>
        <h2>{t("Menu.Lunch")}</h2>
        <div className="divider-img">
          <img src={DividerImg} />
        </div>
      </HeadWrapper>
      <MenuContainerBackground>
        <ResposiveContainer>
          <div className={classes.root}>
            <TabContext value={value}>
              <AppBar position="static">
                <Tabs
                  onChange={handleChange}
                  aria-label="tabs"
                  className={classes.menuNav}
                  centered
                >
                  <Tab className={classes.tab} label="Monday" value="Monday" />
                  <Tab
                    className={classes.tab}
                    label="Tuesday"
                    value="Tuesday"
                  />
                  <Tab
                    className={classes.tab}
                    label="Wednesday"
                    value="Wednesday"
                  />
                  <Tab
                    className={classes.tab}
                    label="Thursday"
                    value="Thursday"
                  />
                  <Tab className={classes.tab} label="Friday" value="Friday" />
                </Tabs>
              </AppBar>
              <div>
                <TabPanel value="Monday">
                  <Items item={Monday} />
                  <NoteContainer>
                    <NoteInfo>{t("Menu.LunchExtraInfo")}</NoteInfo>
                  </NoteContainer>
                </TabPanel>
                <TabPanel value="Tuesday">
                  <Items item={Tuesday} />
                  <NoteContainer>
                    <NoteInfo>{t("Menu.LunchExtraInfo")}</NoteInfo>
                  </NoteContainer>
                </TabPanel>
                <TabPanel value="Wednesday">
                  <Items item={Wednesday} />
                  <NoteContainer>
                    <NoteInfo>{t("Menu.LunchExtraInfo")}</NoteInfo>
                  </NoteContainer>
                </TabPanel>
                <TabPanel value="Thursday">
                  <Items item={Thursday} />
                  <NoteContainer>
                    <NoteInfo>{t("Menu.LunchExtraInfo")}</NoteInfo>
                  </NoteContainer>
                </TabPanel>
                <TabPanel value="Friday">
                  <Items item={Friday} />
                  <NoteContainer>
                    <NoteInfo>{t("Menu.LunchExtraInfo")}</NoteInfo>
                  </NoteContainer>
                </TabPanel>
                <Container
                  maxWidth="sm"
                  align="center"
                  style={{ padding: "3rem" }}
                  className="category__vgl"
                >
                  <p className="extraText">
                    <b style={{ color: "#1d904b" }}>
                      {t("Menu.AlacarteExtraInfo1")}
                    </b>
                    &nbsp;
                    <b style={{ color: "#867c09" }}>
                      {t("Menu.AlacarteExtraInfo2")}
                    </b>
                    &nbsp;
                    <b style={{ color: "#585858" }}>
                      {t("Menu.AlacarteExtraInfo3")}
                    </b>{" "}
                  </p>
                </Container>
              </div>
            </TabContext>
          </div>
        </ResposiveContainer>
      </MenuContainerBackground>
    </MainMenuContainer>
  );
}
