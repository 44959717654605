import LandingPage from "./containers/LandingPage";
import Navbar from "./components/Navbar";
import ToTop from "./components/ToTop";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import ImageAnimation from "./components/animation/ImageAnimation";
import OfferBanner from "./components/OfferBanner";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return loading ? (
    <ImageAnimation />
  ) : (
    <div className="App">
      <Router>
        <Navbar />
        <LandingPage />
        <Footer />
      </Router>
      <OfferBanner />
      <ToTop />
    </div>
  );
}

export default App;

// OPENING SOON
/* 

import Countdown from "react-countdown";
import renderer from "./components/Countdown/Count";


          <Countdown
        date={new Date("2021-03-01T11:00:00")}
        renderer={renderer}
        zeroPadDays={2}
      />
*/
