import React from "react";
import MenuGallery from "../components/Gallery";
import HeroSection from "../components/HeroSection";
import ExploreMenu from "../components/ExploreMenu";
import Reservation from "../components/Reservation";
import MainMenu from "../components/Menu/MainMenu";
import ScrollMenu from "../components/Menu/ScrollMenu";

const LandingPage = () => {
  return (
    <div>
      <HeroSection />
      <ExploreMenu />
      <ScrollMenu />
      <MainMenu />
      <MenuGallery />
      <Reservation />
    </div>
  );
};

export default LandingPage;
