import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import DividerImg from "../../assets/images/divider.png";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import Items from "./Items";
import {
  Soup,
  Appetizer,
  Main,
  ChefSpecial,
  Snacks,
  Rice,
  Breads,
  BeveragesCold,
  BeveragesHot,
  Deserts,
  Kids,
} from "../../data/Menudata";
import { useTranslation } from "react-i18next";

const ResposiveContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 100%;
  }
  @media (min-width: 1280px) {
    max-width: 1640px;
  }

  .beveragesType {
    margin: 10px;
    text-align: center;
    padding: 10px;
    color: #ff7f50;
  }
`;
const MenuContainerBackground = styled.div`
  background-color: #fafafa;
`;
const MainMenuContainer = styled.div`
  height: auto;
  width: 100%;
  overflow: hidden;

  .kids {
    text-align: center;
  }
  .kidsinfo {
    margin: 1.5rem;
  }

  .category__vgl {
    p {
      font-size: 1.4rem;
      color: #6b6767;
      position: relative;
    }

    p::before {
      content: "";
      position: absolute;
      width: 20vw;
      border-bottom: 1px solid #c9c7c7;
      top: -55%;
      left: 0;
    }
    p::after {
      content: "";
      position: absolute;
      width: 20vw;
      border-bottom: 1px solid #c9c7c7;
      bottom: -55%;
      right: 0%;
      @media (max-width: 480px) {
        bottom: -25%;
        width: 35vw;
      }
    }
  }
`;

const HeadWrapper = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  h2 {
    text-align: center;
    font-family: "Rokkitt", serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2;
    letter-spacing: 0.25rem;
    color: #6b6767;
  }

  .divider-img img {
    width: 100px;
    height: 16px;
  }
`;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "#FAFAFA",
    fontFamily: "Rokkitt",
  },
  menuNav: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  tab: {
    color: "#6B6767",
    fontSize: "1rem",
    letterSpacing: "0.02em",
  },
  menuItems: {
    margin: "auto",
    minHeight: "auto",
  },
});

export default function ScrollMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainMenuContainer id="menuSection">
      <HeadWrapper>
        <h2>Ala carte</h2>
        <div className="divider-img">
          <img src={DividerImg} />
        </div>
      </HeadWrapper>
      <MenuContainerBackground>
        <ResposiveContainer>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
              >
                <Tab label="Soup" {...a11yProps(0)} />
                <Tab label="Appetizer" {...a11yProps(1)} />
                <Tab label="Main Course" {...a11yProps(2)} />
                <Tab label="Chef's Special" {...a11yProps(3)} />
                <Tab label="Snacks" {...a11yProps(4)} />
                <Tab label="Rice" {...a11yProps(5)} />
                <Tab label="Bread" {...a11yProps(6)} />
                <Tab label="Beverage" {...a11yProps(7)} />
                <Tab label="Desserts" {...a11yProps(8)} />
                <Tab label="For Kids" {...a11yProps(9)} />
              </Tabs>
            </AppBar>
            <div className={classes.menuItems}>
              <TabPanel value={value} index={0}>
                <Items item={Soup} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Items item={Appetizer} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Items item={Main} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Items item={ChefSpecial} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Items item={Snacks} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Items item={Rice} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <Items item={Breads} />
              </TabPanel>
              <TabPanel value={value} index={7}>
                <h4 className="beveragesType">COLD DRINKS</h4>
                <Items item={BeveragesCold} />
                <hr />
                <h4 className="beveragesType">HOT DRINKS</h4>

                <Items item={BeveragesHot} />
              </TabPanel>
              <TabPanel value={value} index={8}>
                <Items item={Deserts} />
              </TabPanel>
              <TabPanel value={value} index={9}>
                <div className="kids">
                  <h5 className="kidsinfo">{t("Menu.Kids.info")} 7,90€. </h5>
                  <Items item={Kids} />
                </div>
              </TabPanel>
              <Container
                maxWidth="sm"
                align="center"
                style={{ padding: "4rem" }}
                className="category__vgl"
              >
                <p className="extraText">
                  <b style={{ color: "#1d904b" }}>
                    {t("Menu.AlacarteExtraInfo1")}
                  </b>
                  &nbsp;
                  <b style={{ color: "#867c09" }}>
                    {t("Menu.AlacarteExtraInfo2")}
                  </b>
                  &nbsp;
                  <b style={{ color: "#585858" }}>
                    {t("Menu.AlacarteExtraInfo3")}
                  </b>{" "}
                </p>
              </Container>
            </div>
          </div>
        </ResposiveContainer>
      </MenuContainerBackground>
    </MainMenuContainer>
  );
}
