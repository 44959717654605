import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import './i18n';



ReactDOM.render(
<React.Suspense fallback={<div>Loading</div>}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
    </React.Suspense>, document.getElementById("root"));
