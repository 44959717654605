import React from "react";
import styled from "styled-components";
import LandingImage from "../assets/images/LandingImg2.png";
import DividerImg from "../assets/images/divider.png";
import ScrollDown from "./ScrollDown";
import { useTranslation } from "react-i18next";
import Container from '@material-ui/core/Container';

const HeroSectionContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 120vh;
  @media (max-width: 480px) {
    height: 120vh;
  }
  @media (max-width: 1024px) {
    height: 100vh;
  }
  @media (min-width: 1440px) {
    height: auto;
  }
`;

const BackgroundImageSection = styled.img`
  width: 53%;
  max-width: 53%;
  max-height: 96%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1) translate3d(-5%, 5%, 0);
  box-shadow: rgb(0 0 0 / 30%) 0 0 80px -5px;
  border-radius: 50%;
  z-index: 2;

  @media (min-width: 1440px) {
    width: 48%;
  }

  @media (max-width: 480px) {
    width: 100%;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -70%);
    box-shadow: rgb(0 0 0 / 10%) 0 0 80px -5px;
  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
`;

const ContentWrapper = styled.div`
  height: 100vh;
  position: relative;
  background: #fafafa;
  z-index: 1;

  @media (max-width: 1024px) {
    height: 75vh;
  }
  @media (max-width: 480px) {
    height: 100vh;
  }
`;

const DetailSection = styled.div`
  width: 40%;
  height: 50%;
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(-1%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  text-align: center;

  .delicious {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 1.5;
    color: #616367;
    letter-spacing: 0.2em;
  }

  .cuisine {
    font-family: "Rokkitt";
    font-weight: 500;
    font-size: clamp(2rem, 1.5vw, 2.4rem);
    line-height: 2;
    color: #6b6767;
    letter-spacing: 0.2em;
  }

  .bay {
    font-family: "Roboto";
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.5;
    color: #575050;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .vegpoint {
    font-family: "Rhodium Libre";
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2;
    color: rgba(0, 0, 0, 0.57);
    letter-spacing: 0.05em;
  }

  .divider-img img {
    width: 100px;
    height: 16px;
    margin: 1rem 0;
  }

  @media (max-width: 1024px) {
    width: 45%;
    top: 50%;
    .cuisine {
      font-size: 2rem;
    }
  }
  @media (max-width: 768px) {
    width: 45%;
    .delicious {
      font-size: 1.3rem;
      line-height: 1.4;
    }
    .cuisine {
      font-size: 2rem;
      line-height: 1.4;
      letter-spacing: 1px;
    }
    .bay {
      font-size: 1.3;
      letter-spacing: 1.75px;
      line-height: 1.4;
    }
    .vegpoint {
      font-size: 1rem;
      letter-spacing: 1px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    top: 40%;
  }
  @media (min-width: 1440px) {
    width: 45%;
    top: 30%;
    .cuisine {
      font-size: 2rem;
    }
  }
`;

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <HeroSectionContainer>
      <BackgroundFilter>
        <BackgroundImageSection src={LandingImage} />
        <ContentWrapper>
          <DetailSection>
            <div className="delicious">{t("Home.delicious")}</div>
            <div className="cuisine">{t("Home.nepaliCuisine")}</div>
            <div className="bay">{t("Home.onTheBay")}</div>
            <div className="divider-img">
              <img src={DividerImg} />
            </div>
            <div className="vegpoint">{t("Home.welcomeText")}</div>
          </DetailSection>
          <ScrollDown nextLocation="#menuPage" />
        </ContentWrapper>
      </BackgroundFilter>
    </HeroSectionContainer>
  );
};

export default HeroSection;
