import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import DividerImg from "../assets/images/divider.png";
import { AiOutlineMobile } from "react-icons/ai";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import ReservationForm from "./ReservationForm";

const ReservationContainer = styled.div`
  height: 68vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
  }
`;
const HeadWrapper = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    text-align: center;
    font-family: "Rokkitt", serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2;
    letter-spacing: 0.25rem;
    color: #6b6767;
  }

  .divider-img img {
    width: 100px;
    height: 16px;
  }
`;

const ContentWrapper = styled.div`
  height: 30vh;
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
  }
  .dividerOr {
    margin: 1rem;
    color: #963232;
  }
  .phoneInfo {
    font-size: 1rem;
    font-weight: initial;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 1px;
    .phonetext {
      color: #575555;
    }

    .phoneNumber {
      text-decoration: none;
      color: #963232;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    background: "linear-gradient(349deg, #eee 30%, #fff 90%)",
    maxWidth: "27%",
    width: "95%",
    borderRadius: "16px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    border: "none",
    outline: "none",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
    },
  },
}));

export default function Reservation() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <ReservationContainer id="reservation">
      <HeadWrapper>
        <h2>BOOK A TABLE</h2>
        <div className="divider-img">
          <img src={DividerImg} />
        </div>
      </HeadWrapper>
      <ContentWrapper>
        <Button
          size="large"
          variant="contained"
          type="button"
          onClick={handleOpen}
        >
          BOOK A TABLE
        </Button>
        <h3 className="dividerOr">OR</h3>
        <div className="phoneInfo">
          <p className="phonetext">You can book through phone </p>
          <a href="tel:+358 40 6117445" className="phoneNumber">
            <AiOutlineMobile /> (+358 40 6117445)
          </a>
        </div>
        <Modal
          aria-labelledby="book-table-title"
          aria-describedby="book-table-description"
          open={open}
          className={classes.modal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <ReservationForm handleClose={handleClose} />
            </div>
          </Fade>
        </Modal>
      </ContentWrapper>
    </ReservationContainer>
  );
}
