import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import InstagramLogo from "../assets/logos/instagram.svg";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const ResposiveContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    max-width: 1640px;
  }
`;
const MenuContainerBackground = styled.div`
  background-color: rgba(235, 229, 219, 0.4);
`;
const FooterContainer = styled.div`
  width: 100%;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;

  .title-head {
    font-family: "Rokkitt";
    line-height: 2;
    font-size: 1.9rem;
    color: rgba(150, 115, 38, 0.95);
  }
  .footer-link {
    padding: 0.3em 1.2em;
    border: 1px solid #000;
    border-radius: 2px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
  }

  @media (max-width: 480px) {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const LeftContainer = styled.div`
  width: 50%;
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h3 ~ p {
    color: #6b6767;
    font-family: "Rokkitt";
    width: 100%;
    border-right: 1px solid #6d6464;
    @media (max-width: 480px) {
      border-right: none;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    padding-left: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
    width: 100%;
    border-right: none;
    align-items: center;
  }
`;
const RightContainer = styled.div`
  width: 50%;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  h3 ~ p {
    color: #6b6767;
    font-family: "Rokkitt";
  }

  @media (max-width: 768px) {
    padding-right: 1.5rem;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
  }
`;

const NavSection = styled.div`
  position: absolute;
  display: flex;
  top: 3%;
  left: 50%;
  transform: translate(-50%, 0);
  a {
    color: rgba(107, 103, 103, 0.93);
    cursor: pointer;
    margin: 0 1rem;
    text-decoration: none;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const CopyRightSection = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  color: #9e9898;
`;
const SocialIcons = styled.div`
  a {
    display: inline-block;
    color: #fff;
    margin: 0 0.5rem;
    font-size: 0.7rem;
    transition: transform 250ms;

    img {
      width: 19px;
      height: auto;
    }
  }
  a:hover {
    transform: translateY(-3px);
  }

  .facebook {
    color: #0e8cf1;
  }

  .instagram {
    color: #000;
  }
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0.5rem;
  }
`;
const Footer = () => {
  const { t } = useTranslation();
  return (
    <MenuContainerBackground>
      <ResposiveContainer>
        <FooterContainer id="footerSection">
          <NavSection>
            <a href="#">{t("Footer.home")}</a>
            <a href="#menuSection">{t("Footer.menu")}</a>
            <a href="#reservation">{t("Footer.reservation")}</a>
            <a href="#gallery">{t("Footer.gallery")}</a>
          </NavSection>
          <LeftContainer>
            <h3 className="title-head">{t("Footer.contactInfo")}</h3>
            <p>{t("Footer.address")}</p>
            <p>{t("Footer.booking")}</p>
            <p>{t("Footer.email")}</p>
            <p>www.vegpoint.fi</p>
            <p>{t("Footer.follow")}</p>
            <SocialIcons className="soc-icons">
              <a
                href="https://www.facebook.com/Vegpoint.fi"
                target="_blank"
                className="facebook social"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>

              <a
                href="https://www.instagram.com/vegpoint.fi"
                target="_blank"
                className="instagram social"
              >
                <img src={InstagramLogo} />
              </a>
            </SocialIcons>
          </LeftContainer>
          <RightContainer>
            <h3 className="title-head">{t("Footer.restaurantHours")}</h3>
            <p>
              <b>{t("Footer.lunch")}</b>
            </p>
            <p> {t("Footer.lunchDayTime")}</p>
            <p>
              {" "}
              <b>Ala-Carte:</b>
            </p>
            <p> {t("Footer.alacarteDayTime1")}</p>
            <Typography color="textSecondary" style={{ paddingTop: "0.2rem" }}>
              {" "}
              <span style={{ color: "#9B7930", fontWeight: "900" }}>
                {t("Footer.alacarteDayTime2")}
              </span>
            </Typography>
          </RightContainer>
          <CopyRightSection>
            © 2021 Veg Point, All Rights Reserved
          </CopyRightSection>
        </FooterContainer>
      </ResposiveContainer>
    </MenuContainerBackground>
  );
};

export default Footer;
