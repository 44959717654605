import React from "react";
import Vegpoint from "../../assets/logos/logo1.png";
import styled from "styled-components";

const AnimationContainer = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: example;
  animation-duration: 3s;

  img {
    width: 40%;
    height: auto;
    @media (max-width:480px){
        width:60%;
    }
  }

  @keyframes example {
    from {
      opacity: 1;
      background: rgb(253, 252, 252);
    }
    to {
      opacity: 0;
      background: rgb(243, 243, 242);
    }
  }

  @-webkit-keyframes example {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes example {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes example {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default function ImageAnimation() {
  return (
    <AnimationContainer>
      <img src={Vegpoint}></img>
    </AnimationContainer>
  );
}
